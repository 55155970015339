var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm._l(_vm.list, function(item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "a-tooltip",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px"
                                  },
                                  attrs: { src: _vm.showImageTip(item) },
                                  on: {
                                    click: function($event) {
                                      return _vm.previewImgTip(index)
                                    }
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("div", { staticClass: "image-box" }, [
                        _c("img", {
                          staticClass: "image",
                          attrs: { src: _vm.showImage(item) },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(index)
                            }
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: " tip-id",
                            on: {
                              click: function($event) {
                                return _vm.handleGoToOpenNewWindow(item.id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.id))]
                        ),
                        item.recognizerVersion
                          ? _c("div", { staticClass: " tip-model" }, [
                              _vm._v(_vm._s(item.recognizerVersion))
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "tip" }, [
                          _vm._v(_vm._s(item.distance.substring(0, 4)))
                        ]),
                        _c(
                          "div",
                          { staticClass: " tip-checked" },
                          [
                            _c("a-checkbox", {
                              staticClass: "checked",
                              model: {
                                value: item.checkStatus,
                                callback: function($$v) {
                                  _vm.$set(item, "checkStatus", $$v)
                                },
                                expression: "item.checkStatus"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "name" })
                ],
                1
              ),
              _c("div", { staticClass: "footer" }, [
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.moveClick(item)
                      }
                    }
                  },
                  [_vm._v("操作")]
                )
              ])
            ])
          }),
          _vm.list.length <= 0
            ? _c("a-empty", {
                staticClass: "null",
                attrs: { description: false }
              })
            : _vm._e()
        ],
        2
      ),
      _c("ConfirmMove", { ref: "move", on: { success: _vm.editSuccess } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }