<template>
  <a-modal
    v-model="pushModal"
    :width='800'
    title="操作"
  >
    <template #footer>
      <a-button key="back" @click="cancelPushModal">取消</a-button>
      <a-button v-if="editType !== 'sample'" key="submit" type="primary" @click="confirmPushModal('bottom')">确认进底库</a-button>
      <a-button v-if="editType !== 'bottom'" key="submit" type="primary" @click="confirmPushModal('sample')">确认进样本库</a-button>
      </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 220px"
          v-model="curItem.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 280px"
          v-model="curItem.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.coinVersionId"
              :value="item.coinVersionId"
          >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      loading: false,
      editType: '',
      batchIds: []
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.curItem = {};
      }
    },
  },
  methods: {
    async show(data) {
      this.pushModal = true;
      this.curItem = JSON.parse(JSON.stringify(data))
      const { coinKindId, batchIds, coinKindItemId, coinVersionId, coinKindName, type} = data;
      this.batchIds = batchIds
      this.editType = type
      await this.coinSearch(coinKindName)
      await this.changeCoin();
      await this.getVersionType(coinKindId, coinKindItemId)
      if (!coinKindItemId) return;
      this.$set(this.curItem, "coinKindItemId", coinKindItemId);
      this.$set(this.curItem, "coinKindItemVersionId", coinVersionId);
    },
    // 大币种改变
    async changeCoin() {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemVersionId;
      if (!this.curItem.coinKindId) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.$set(this.curItem, "coinSid", coin.sid);
      this.$set(this.curItem, "coinKindName", coin.coinKindName);
      await this.coinItemSearch();
    },
    //  选择小币种
    async changeCoinItem() {
      delete this.curItem.coinKindItemVersionId;
      if (!this.curItem.coinKindItemId) return;
      await this.getVersionType(this.curItem.coinKindId, this.curItem.coinKindItemId)
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str},
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    /** 获取版别 */
    async getVersionType(coinId, coinItemId) {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId,
          coinItemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal(type) {
      this.$loading.show()
      const {
        coinKindId,
        coinKindItemId,
        coinKindItemVersionId,
      } = this.curItem;
      const recordIds = this.batchIds.join(',')
      const params = {
        recordIds,
        isTest: 0,
        coinId: coinKindId,
        itemId: coinKindItemId,
        versionId: coinKindItemVersionId,
        type: 5
      }
      if (type === 'bottom') delete params.type
      const url = type === 'bottom'
          ? '/dq_admin/duibanUserRecord/pushAsynDikuBatch'
          : '/dq_admin/duibanUserRecord/confirmToEnterTheSampleLibraryBatch'
      const res = await this.axios.get(url, {
        params
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>