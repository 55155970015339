<template>
  <div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="content">
          <a-tooltip>
            <template #title>
              <img
                  style="width: 200px;height: 200px;"
                  :src="showImageTip(item)"
                  @click="previewImgTip(index)"
              />
            </template>
            <div class="image-box">
              <img
                  class="image"
                  :src="showImage(item)"
                  @click="previewImg(index)"
              />
              <div class=" tip-id" @click="handleGoToOpenNewWindow(item.id)">{{ item.id }}</div>
              <div class=" tip-model" v-if="item.recognizerVersion">{{ item.recognizerVersion }}</div>
              <div class="tip">{{ item.distance.substring(0, 4) }}</div>
              <div class=" tip-checked">
                <a-checkbox class="checked" v-model:checked="item.checkStatus"></a-checkbox>
              </div>
            </div>
          </a-tooltip>

          <div class="name">
<!--            {{`${item.coinKindItemName}-${item.coinVersionName}`}}-->
          </div>
          <!-- <div class="score">{{item.sourceInfo || ""}}</div> -->
        </div>
        <div class="footer">
          <a
            class="move-btn"
            @click="moveClick(item)"
          >操作</a>
        </div>
      </div>
      <a-empty :description="false" class="null" v-if="list.length <= 0" />
    </div>
    <ConfirmMove
      ref="move"
      @success="editSuccess"
    ></ConfirmMove>
  </div>

</template>

<script>

import ConfirmMove from "@/views/cmsPage/versionManage/duibanBatch/BatchMove.vue";
export default {
  props: {
    coinKindName: {
      type: String
    },
    list: {
      default: [],
    },
    imageType: {
      default: 0, //0正面 1背面
    },
    params: {
      type: Object
    }
  },
  components: {
    ConfirmMove,
  },
  methods: {
    /** 去对版详情页，打开新的窗口 */
    handleGoToOpenNewWindow(id) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?recordId=${id}`;
      window.open(url)
    },
    editSuccess() {
      this.$emit("editSuccess");
    },
    moveClick(item) {
      const data = {
        batchIds: [item.id],
        type: 'over',
        coinKindId: this.params.coinId,
        coinKindItemId: this.params.coinItemId,
        coinVersionId: this.params.versionId,
        coinKindName: this.coinKindName
      }
      this.$refs["move"].show(data);
    },
    showImage(item) {
      if (this.imageType == 0) {
        return item.frontImage;
      } else {
        return item.backImage;
      }
    },
    showImageTip(item) {
      if (this.imageType == 0) {
        return item.duiBanConfirmFrontImage;
      } else {
        return item.duiBanConfirmBackImage;
      }
    },
    // 预览
    previewImgTip(index) {
      const list = this.list.map((el) => {
        let url;
        if (this.imageType == 0) {
          url = el.duiBanConfirmFrontImage;
        } else {
          url = el.duiBanConfirmBackImage;
        }
        return {
          img_url: url,
        };
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    // 预览
    previewImg(index) {
      const list = this.list.map((el) => {
        let url;
        if (this.imageType == 0) {
          url = el.frontImage;
        } else {
          url = el.backImage;
        }
        return {
          img_url: url,
        };
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.null {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .image {
          width: 200px;
          height: 200px;
          border-radius: 4px;
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 12px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
        .tip-checked {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 50px;
          height: 50px;
          .checked {
            position: absolute;
            width: 35px;
            height: 35px;
            bottom: 0;
            right: 0;
            label {
              span {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .tip-model {
          position: absolute;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
          right: 0;
          top: 0;
          color: #000;
        }
        .tip-id {
          position: absolute;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
          left: 0 ;
          top: 0 ;
          cursor: pointer;
          color: #2d8cf0 ;
          text-decoration: underline;
        }
      }

      .name,
      .score {
        text-align: center;
        margin-top: 2px;
      }
    }
    .footer {
      margin-top: 2px;
      a {
        color: red;
        margin-right: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .move-btn {
        color: #1890ff;
      }
    }
  }
}
</style>